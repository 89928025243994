import React from 'react'
import { Col } from "react-bootstrap";
import logo from '../images/docb.png'
const Header = ({ siteTitle }) => (
  <>
  <Col>
        <a href="../" className="link-no-style">
          <img src={logo}/>
        </a>
  </Col>
  </>
)

export default Header

